<template>
    <common-form
            ref="form"
            url="web/iot/device/authorization/import"
            title="导入语音授权"
            :items="items"
            @success="$emit('success')"></common-form>
</template>

<script>

    export default {
        data() {
            return {
                edit: false,
                modelOptions:[],
                vendorOptions:[]
            }
        },
        computed: {
            items() {
                let arr = [
                    {
                        type: "number",
                        name: "导入额度",
                        key: "limit",
                        required:true,
                    },
                    {
                        type: "textarea",
                        name: "MAC",
                        key: "macList",
                        required:true,
                        placeholder:"输入要授权的设备MAC，每个MAC一行，不区分大小写",
                        minRows:20
                    }
                ]
                return arr
            }
        },
        methods: {
            show(model = {}) {
                this.edit = model.id > 0
                model.limit=1
                this.$refs.form.show(model)
            },
        }
    }
</script>
<style lang="less" scoped>
   
</style>
