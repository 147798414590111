<template>
  <page-content>

    <common-table
      ref="table"
      path="web/iot/device"
      search-placeholder="搜索设备名称/MAC"
      :columns="columns"
      :queryParams="queryParams" >

      <template slot="search">
        <a-button style="margin-right: 30px" ghost v-hasPermission="['device:add']"  type="primary" @click="add">新增</a-button>

        <a-button style="margin-right: 30px" ghost   type="primary" @click="importAuthorizationLimit">导入语音授权额度</a-button>

        <a-radio-group v-model="queryParams.status" @change="onStatusChange">
          <a-radio-button :value="0">全部</a-radio-button>
          <a-radio-button :value="1">在线</a-radio-button>
          <a-radio-button :value="2">离线</a-radio-button>
          <a-radio-button :value="3">报警</a-radio-button>
        </a-radio-group>

      </template>

      <template slot="icon" slot-scope="{record}">
        <a-avatar shape="square" :src="record.devicePicture" style="background: #F8F8F8;margin-right: 10px"/>
        {{record.productName}}
      </template>
      <template slot="status" slot-scope="{record}">
        <a-tag v-if="!record.online" color="#D1D1D1">离线</a-tag>
        <a-tag v-else-if="record.alarm" color="#EEC900">报警</a-tag>
        <a-tag v-else color="#00ff99">在线</a-tag>
      </template>
      <template slot="operation" slot-scope="{record}">
        <action-view @click="view(record)"></action-view>
        <action-delete @click="remove(record)"></action-delete>
      </template>

    </common-table>

    <DeviceEdit ref="deviceEdit"></DeviceEdit>
    <ImportAuthorizationLimit ref="importAuthorizationLimit" @success="getList"></ImportAuthorizationLimit>

  </page-content>

</template>
<script>

  import DeviceEdit from "./DeviceEdit";
  import ImportAuthorizationLimit from "./_components/ImportAuthorizationLimit"
  export default {
    props:["productKey"],
    components:{DeviceEdit,ImportAuthorizationLimit},
    data () {
      return {
        queryParams: {
          // productKey: this.productKey,
          address: '',
          status:0,
        },
        productList: [],
        statistics: {
          total: 0,
          totalOnline: 0,
          totalToday: 0,
          totalWeek: 0
        },
        vendorList:[]
      }
    },
    created () {

      this.productKey = this.$route.params.productkey || ''
      // this.getProductList()
      this.getVendorList()
    },
    computed: {
      columns () {
        let productFilter = [{text: '全部', value: ''}]
        this.productList.forEach(item=>{
          productFilter.push({text: item.name, value: item.productKey})
        })
        let vendorFilter = [{text: '全部', value: ''}]
        this.vendorList.forEach(item=>{
          vendorFilter.push({text: item.name, value: item.id})
        })
        let valueFormatter = (value)=> value==0? "--" : value
        return [
          // {
          //   title: '产品',
          //   dataIndex: 'productKey',
          //   scopedSlots: {customRender: 'icon'},
          //   filters: productFilter,
          //   filterMultiple: false,
          // },

          {
            title: '状态',
            dataIndex: 'status',
            scopedSlots: {customRender: 'status'},
            // filters: [
            //   {text: '全部', value: '0'},
            //   {text: '在线', value: '1'},
            //   {text: '离线', value: '3'}
            // ],
            // filterMultiple: false,
          },
          {
            title: '名称',
            dataIndex: 'nickname',
          },
          {
            title: '设备MAC',
            dataIndex: 'name'
          },
          {
            title: '经销商',
            dataIndex: 'vendorId',
            filters: vendorFilter,
            filterMultiple: false,
            customRender(text,record){
              return record.vendorName
            }
          },
          {
            title: '语音授权额度',
            dataIndex: 'authorizationLimit',
            customRender(text,record){
              return text || 0
            },
            sorter:true,
            filters: [
              {text: '全部', value: ''},
              {text: '额度为 0', value: 0},
              {text: '额度为 1', value: 1},
              {text: '额度为 2', value: 2},
              {text: '额度为 3', value: 3},
              {text: '额度为 4', value: 4},
              {text: '额度为 5', value: 5},
              {text: '额度为 6', value: 6},
              {text: '额度为 7', value: 7},
              {text: '额度为 8', value: 8},
              {text: '额度为 9', value: 9},
              {text: '额度为 10', value: 10},
            ],
            filterMultiple: false,
          },
          {
            title: '是否含语音授权',
            dataIndex: 'hasImportAuthorization',
            customRender(text,record){
              return text ? "是" : "否"
            },
            filters: [
              {text: '全部', value: ''},
              {text: '是', value: true},
              {text: '否', value: false},
            ],
            filterMultiple: false,
          },
          {
            title: '操作',
            dataIndex: 'operation',
            scopedSlots: {customRender: 'operation'},
            width: 80
          }
        ]
      }
    },
    methods: {

      add(){
        this.$refs.deviceEdit.show()
      },
      productChange (productKey) {
        this.queryParams.productKey = productKey
        this.getList()
      },

      onStatusChange(){
        this.$refs.table.resetPage()
        this.$refs.table.getData()
      },

      view (record) {
        this.$router.push(`/device/${record.id}`)
      },
      remove(record){
        let that=this
        this.$confirm({
          title: '确定删除设备 ' + record.nickname,
          content: '该操作无法撤销',
          centered: true,
          onOk() {
            that.$delete('web/iot/water/device/' + record.id).then(() => {
              that.$message.success('删除成功')
              that.getList()
            })
          }
        })
      },
      getList () {
        this.$refs.table.getData()
      },
      getProductList () {
        this.$get('product').then((r) => {
          let data = r.data
          this.productList = data
        })
      },
      getVendorList(){
        this.$get('vendor?pageSize=100').then((r) => {
          let data = r.data
          this.vendorList = data.rows
        })
      },

      importAuthorizationLimit(){
        this.$refs.importAuthorizationLimit.show()
      }

    }
  }
</script>
<style lang="less" scoped>
  .data-area {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
</style>
